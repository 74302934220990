import * as Yup from 'yup';
import { EMAIL_TYPE } from '../validationForms';
import { REQUIRED_MESSAGE } from '../validationMessages';

export const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required(REQUIRED_MESSAGE)
        .matches(EMAIL_TYPE, 'The email format is incorrect.')
        .max(255, 'Email must be max 255 characters.'),
    password: Yup.string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required(REQUIRED_MESSAGE),
});
