import React, { useEffect } from 'react';

import GoogleConnection from 'components/socialConnections/GoogleConnection';
import { initialValues } from 'utils/validations/signIn/formInitialValues';
import { validationSchema } from 'utils/validations/signIn/schemas';
import { handleErrorMessages } from 'helpers/handleErrorMessages';
import { useFormik, FormikProvider } from 'formik';
import { authSlice } from 'store/slices/AuthSlice';
import { authAPI } from 'store/services/auth';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import useFBLogin from 'hooks/useFBLogin';
import { Link } from 'react-router-dom';
import Field from 'components/Field';
import { Storage } from 'storage';

const SignIn = () => {
    const { checkLoginStatus } = useFBLogin();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { setUser } = authSlice.actions;

    const [signInMutate, { isLoading, isSuccess, isError, data, error }] =
        authAPI.useSignInMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            signInMutate(values);
        },
    });

    useEffect(() => {
        if (isSuccess && data) {
            const { token, user } = data;
            Storage.setItem('accessToken', token);

            dispatch(setUser(user));
            navigate('/profile');
        }

        if (isError) {
            const errors = handleErrorMessages(error.data.errors);
            formik.setErrors(errors);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isSuccess, isError, error]);

    return (
        <div>
            <div>
                <Link to="/sign-up">Sign Up</Link>
            </div>
            <h1>Sign In</h1>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Field type="email" label="Email" {...formik.getFieldProps('email')} />
                    <Field type="password" label="Password" {...formik.getFieldProps('password')} />
                    <button
                        className="btn btn-grad w-full btn-lg "
                        type="submit"
                        disabled={isLoading}
                    >
                        Sign In
                    </button>
                </form>
            </FormikProvider>
            <div>
                <Link to="/forgot-password">Forgot Password</Link>
            </div>
            <button onClick={checkLoginStatus}>Log IN With Facebook</button>
            <GoogleConnection />
        </div>
    );
};

export default SignIn;
