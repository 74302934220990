import React, { useEffect } from 'react';

import { authAPI } from 'store/services/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { showErrorMessages } from 'helpers/handleErrorMessages';

const EmailConfirmation = () => {
    const navigate = useNavigate();
    const [confirmationMutation, { data, isLoading, isSuccess, isError, error }] =
        authAPI.useUserConfirmationMutation();

    const [searchParams] = useSearchParams();
    const token = searchParams.get('confirmation_token');

    useEffect(() => {
        if (isSuccess) {
            toast.success(data.success);
            navigate('/sign-in');
        }

        if (isError) showErrorMessages(error?.data?.errors);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, isError, error, data]);

    const onConfirm = () => {
        if (token) return confirmationMutation({ confirmation_token: token });

        toast.error('You do not have confirmation key.');
    };

    return (
        <div>
            <h1>Email Confirmation</h1>
            <button onClick={onConfirm} disabled={isLoading}>
                Confirm
            </button>
        </div>
    );
};

export default EmailConfirmation;
