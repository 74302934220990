import React, { useEffect } from 'react';

import GoogleConnection from 'components/socialConnections/GoogleConnection';
import { initialValues } from 'utils/validations/signUp/formInitialValues';
import { validationSchema } from 'utils/validations/signUp/schemas';
import { handleErrorMessages } from 'helpers/handleErrorMessages';
import { useFormik, FormikProvider } from 'formik';
import { authAPI } from 'store/services/auth';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Field from 'components/Field';
import useFBLogin from 'hooks/useFBLogin';

const SignUp = () => {
    const { checkLoginStatus } = useFBLogin();
    const navigate = useNavigate();

    const [signUpMutate, { isLoading, data, isSuccess, isError, error }] =
        authAPI.useSignUpMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            signUpMutate(values);
        },
    });

    useEffect(() => {
        if (isSuccess) {
            toast.success(data.success);
            navigate('/sign-in');
        }

        if (isError) {
            const errors = handleErrorMessages(error.data.errors);
            formik.setErrors(errors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, error, isError, data]);

    return (
        <div>
            <div>
                <Link to="/sign-in">Sign In</Link>
            </div>
            <h1>Sign Up</h1>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Field type="text" label="First Name" {...formik.getFieldProps('first_name')} />
                    <Field type="text" label="Last Name" {...formik.getFieldProps('last_name')} />
                    <Field type="email" label="Email" {...formik.getFieldProps('email')} />
                    <Field
                        type="text"
                        label="Company Name"
                        {...formik.getFieldProps('company_name')}
                    />
                    <Field type="text" label="Title" {...formik.getFieldProps('title')} />
                    <Field type="text" label="Position" {...formik.getFieldProps('position')} />
                    <Field type="password" label="Password" {...formik.getFieldProps('password')} />
                    <Field
                        type="password"
                        label="Password confirmation"
                        {...formik.getFieldProps('password_confirmation')}
                    />
                    <Field
                        type="checkbox"
                        text="I accept terms of use and privacy policy and agree to receive newsletters."
                        {...formik.getFieldProps('privacy_policy')}
                    />
                    <button
                        className="btn btn-grad w-full btn-lg "
                        type="submit"
                        disabled={isLoading}
                    >
                        Sign In
                    </button>
                </form>
            </FormikProvider>
            <button onClick={checkLoginStatus}>Log IN With Facebook</button>
            <GoogleConnection />
        </div>
    );
};

export default SignUp;
