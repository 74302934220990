export const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    title: '',
    position: '',
    password: '',
    password_confirmation: '',
    privacy_policy: false,
};
