import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <>
            <div>
                <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            <div>
                <Link to="/sign-in">Sign In</Link>
            </div>
            <div>
                <Link to="/sign-up">Sign Up</Link>
            </div>
        </>
    );
};

export default Home;
