import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Storage } from 'storage';
import { authAPI } from 'store/services/auth';

import { authSlice } from 'store/slices/AuthSlice';

const Auth = () => {
    const [skip, setSkip] = useState(true);
    const dispatch = useDispatch();

    const { data: userInfo } = authAPI.useGetUserQuery('', {
        skip,
    });

    const { setUser } = authSlice.actions;

    useEffect(() => {
        // if we don't have an access token we don't call this request
        const accessToken = Storage.getItem('accessToken');
        if (accessToken) setSkip(false);
    }, []);

    useEffect(() => {
        if (userInfo) {
            dispatch(setUser(userInfo));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);

    return null;
};

export default Auth;
