import { toast } from 'react-toastify';

export function handleErrorMessages(errors) {
    const response = {};

    for (let key in errors) {
        const message = `${key} ${errors[key]}`;
        response[key] = message.charAt(0).toUpperCase() + message.slice(1);
    }

    return response;
}

export function showErrorMessages(errors) {
    const errorsObj = handleErrorMessages(errors);
    const errorsArr = Object.entries(errorsObj);

    errorsArr.forEach(([_, error]) => toast.error(error));
}
