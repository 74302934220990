import * as Yup from 'yup';
import { REQUIRED_MESSAGE } from '../validationMessages';

export const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required(REQUIRED_MESSAGE),
    password_confirmation: Yup.string()
        .required(REQUIRED_MESSAGE)
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
