import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Storage } from 'storage';
import { authSlice } from 'store/slices/AuthSlice';

const Profile = () => {
    const { user } = useSelector((state) => state.auth);
    const { deleteUser } = authSlice.actions;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (!user) return <div>Loading ...</div>;

    const onLogout = () => {
        Storage.clear();

        dispatch(deleteUser());
        navigate('/sign-in');
    };

    return (
        <div>
            <div style={{ margin: '10px' }}>
                Full Name - {user.first_name} {user.last_name}
            </div>
            <div style={{ margin: '10px' }}>Email - {user.email}</div>
            <div style={{ margin: '10px' }}>Position - {user.position}</div>
            <div style={{ margin: '10px' }}>Title - {user.title}</div>
            <button onClick={onLogout}>Logout</button>
        </div>
    );
};

export default Profile;
