import { createSlice } from '@reduxjs/toolkit';
import { Storage } from 'storage';

const initialState = {
    user: null,
    isLoggedIn: Storage.getItem('accessToken') ? true : false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
            state.isLoggedIn = true;
        },
        deleteUser(state) {
            state.user = null;
            state.isLoggedIn = false;
        },
    },
});

export default authSlice.reducer;
