import * as Yup from 'yup';
import { EMAIL_TYPE } from '../validationForms';
import { REQUIRED_MESSAGE } from '../validationMessages';

export const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(REQUIRED_MESSAGE).trim(),
    last_name: Yup.string().required(REQUIRED_MESSAGE).trim(),
    email: Yup.string()
        .required(REQUIRED_MESSAGE)
        .matches(EMAIL_TYPE, 'The email format is incorrect.')
        .max(255, 'Email must be max 255 characters.'),
    company_name: Yup.string().max(100, 'Company Name must be max 100 letters.'),
    title: Yup.string().max(100, 'Title must be max 100 letters.'),
    position: Yup.string().max(100, 'Position must be max 100 letters.'),
    password: Yup.string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required(REQUIRED_MESSAGE),
    password_confirmation: Yup.string()
        .required(REQUIRED_MESSAGE)
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
