import {
    USERS_CONFIRMATION,
    USERS_SIGN_IN,
    USERS_SIGN_UP,
    USERS_INFO,
    USERS_PASSWORD,
} from './auth.urls';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from '../util';

export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        signUp: builder.mutation({
            query: (body) => ({
                url: USERS_SIGN_UP,
                method: 'POST',
                body,
            }),
        }),
        signIn: builder.mutation({
            query: (body) => ({
                url: USERS_SIGN_IN,
                method: 'POST',
                body,
            }),
        }),
        userConfirmation: builder.mutation({
            query: (body) => ({
                url: USERS_CONFIRMATION,
                method: 'POST',
                body,
            }),
        }),
        passwordForgot: builder.mutation({
            query: (body) => ({
                url: USERS_PASSWORD,
                method: 'POST',
                body,
            }),
        }),
        passwordReset: builder.mutation({
            query: (body) => ({
                url: USERS_PASSWORD,
                method: 'PATCH',
                body,
            }),
        }),
        getUser: builder.query({
            query: () => ({
                url: USERS_INFO,
            }),
        }),
    }),
});
