import React from 'react';

import * as ROUTES_URLS from './route.urls';
import { Navigate } from 'react-router';
import {
    EmailConfirmation,
    ForgotPassword,
    Home,
    PrivacyPolicy,
    Profile,
    SignIn,
    SignUp,
    ResetPassword,
} from 'pages';

export const publicRoutes = [
    {
        title: 'Home',
        path: ROUTES_URLS.HOME,
        element: <Home />,
    },
    {
        title: 'Privacy Policy',
        path: ROUTES_URLS.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
    },
    {
        title: 'Sign Up',
        path: ROUTES_URLS.SIGN_UP,
        element: <SignUp />,
    },
    {
        title: 'Sign In',
        path: ROUTES_URLS.SIGN_IN,
        element: <SignIn />,
    },
    {
        title: 'Email Confirmation',
        path: ROUTES_URLS.EMAIL_CONFIRMATION,
        element: <EmailConfirmation />,
    },
    {
        title: 'Forgot Password',
        path: ROUTES_URLS.FORGOT_PASSWORD,
        element: <ForgotPassword />,
    },
    {
        title: 'Reset Password',
        path: ROUTES_URLS.RESET_PASSWORD,
        element: <ResetPassword />,
    },
    {
        title: 'Not Found',
        path: '*',
        element: (
            <div>
                <Navigate to={ROUTES_URLS.SIGN_IN} />
                {/* or we can show "404 Not Found" page */}
            </div>
        ),
    },
];

export const protectedRoutes = [
    {
        title: 'Profile',
        path: ROUTES_URLS.PROFILE,
        element: <Profile />,
    },
    {
        title: 'Not Found',
        path: '*',
        element: (
            <div>
                <Navigate to={ROUTES_URLS.PROFILE} />
                {/* or we can show "404 Not Found" page */}
            </div>
        ),
    },
];
