import React, { useEffect } from 'react';

import { initialValues } from 'utils/validations/resetPassword/formInitialValues';
import { validationSchema } from 'utils/validations/resetPassword/schemas';
import { showErrorMessages } from 'helpers/handleErrorMessages';
import { Link, useSearchParams } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import { authAPI } from 'store/services/auth';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Field from 'components/Field';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const reset_password_token = searchParams.get('reset_password_token');

    const [resetPasswordMutation, { isLoading, isSuccess, isError, data, error }] =
        authAPI.usePasswordResetMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            resetPasswordMutation({ ...values, reset_password_token });
        },
    });

    useEffect(() => {
        if (isSuccess) {
            toast.success(data.success);
            navigate('/sign-in');
        }

        if (isError) showErrorMessages(error.data.errors);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isSuccess, isError, error]);

    return (
        <div>
            <div>
                <Link to="/sign-in">Sign In</Link>
            </div>
            <h1>Reset Password</h1>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Field type="password" label="Password" {...formik.getFieldProps('password')} />
                    <Field
                        type="password"
                        label="Password confirmation"
                        {...formik.getFieldProps('password_confirmation')}
                    />
                    <button
                        className="btn btn-grad w-full btn-lg "
                        type="submit"
                        disabled={isLoading}
                    >
                        Send
                    </button>
                </form>
            </FormikProvider>
        </div>
    );
};

export default ResetPassword;
