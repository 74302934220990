import React from 'react';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { setupStore } from 'store';
import App from './App';
import './index.css';

// eslint-disable-next-line no-undef
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const container = document.getElementById('root');
const store = setupStore();

if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <GoogleOAuthProvider clientId={googleClientId}>
                    <App />
                </GoogleOAuthProvider>
            </Provider>
        </React.StrictMode>,
    );
}

reportWebVitals();
