import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { SIGN_IN } from 'routes/route.urls';
import { Storage } from 'storage';
import { USERS_SIGN_IN } from './auth/auth.urls';

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_BASE_URL;

export const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
        const accessToken = Storage.getItem('accessToken');

        if (accessToken) headers.set('Authorization', accessToken);

        return headers;
    },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    const tokenError = result?.error?.data?.errors?.token;

    if (args.url !== USERS_SIGN_IN && (result?.error?.status === 401 || tokenError)) {
        Storage.clear();
        window.location.pathname = SIGN_IN;
        return;
    }
    return result;
};
