import React, { useEffect } from 'react';

import { initialValues } from 'utils/validations/forgotPassword/formInitialValues';
import { validationSchema } from 'utils/validations/forgotPassword/schemas';
import { showErrorMessages } from 'helpers/handleErrorMessages';
import { useFormik, FormikProvider } from 'formik';
import { authAPI } from 'store/services/auth';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Field from 'components/Field';

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [forgotPasswordMutation, { isLoading, isSuccess, isError, data, error }] =
        authAPI.usePasswordForgotMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            forgotPasswordMutation(values);
        },
    });

    useEffect(() => {
        if (isSuccess) {
            toast.success(data.success);
            navigate('/sign-in');
        }

        if (isError) showErrorMessages(error.data.errors);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isSuccess, isError, error]);

    return (
        <div>
            <div>
                <Link to="/sign-in">Sign In</Link>
            </div>
            <h1>Forgot Password</h1>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Field type="email" label="Email" {...formik.getFieldProps('email')} />
                    <button
                        className="btn btn-grad w-full btn-lg "
                        type="submit"
                        disabled={isLoading}
                    >
                        Send
                    </button>
                </form>
            </FormikProvider>
        </div>
    );
};

export default ForgotPassword;
