import { GoogleLogin } from '@react-oauth/google';
import React from 'react';

const GoogleConnection = () => {
    return (
        <div style={{ width: '200px' }}>
            <GoogleLogin
                onSuccess={(credentialResponse) => {
                    console.log(credentialResponse);
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
            />
        </div>
    );
};

export default GoogleConnection;
