import React, { useEffect } from 'react';

import { protectedRoutes, publicRoutes } from './routes/route.pages';
import { createBrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RouterProvider } from 'react-router';
import useFBLogin from './hooks/useFBLogin';
import { useSelector } from 'react-redux';
import Auth from 'components/auth';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { init } = useFBLogin();

    const routes = isLoggedIn ? protectedRoutes : publicRoutes;
    const router = createBrowserRouter(routes);

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="App">
            <Auth />
            <RouterProvider router={router} />
            <ToastContainer />
        </div>
    );
}

export default App;
