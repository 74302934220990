import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/AuthSlice';
import { authAPI } from './services/auth';

const rootReducer = combineReducers({
    auth: authReducer,
    [authAPI.reducerPath]: authAPI.reducer,
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authAPI.middleware),
    });
};
