import React from 'react';

import { useField } from 'formik';

const Field = (props) => {
    const { label, text, ...attr } = props;
    const [field, meta] = useField(props.name);

    const view = () => {
        switch (attr.type) {
            case 'textarea':
                return <textarea {...field} {...attr} />;
            case 'select':
                return null;
            default:
                return <input {...field} {...attr} step="any" />;
        }
    };
    return (
        <div className="space-y-10">
            {label && <span className="nameInput">{label}</span>}
            <div className="confirm">
                {view()}
                {text}
                {meta.touched && Boolean(meta.error) && (
                    <div style={{ padding: '10px 0', color: '#d50000' }}>{meta.error}</div>
                )}
            </div>
        </div>
    );
};

export default Field;
