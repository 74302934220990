/* eslint-disable no-undef */
const APP_ID = process.env.REACT_APP_ENV_FACEBOOK_APP_ID;
const APP_SECRET = process.env.REACT_APP_ENV_FACEBOOK_APP_SECRET;

const useFBLogin = () => {
    let loginStatus = null;
    let userData = null;
    let userLongLifeToken = null;

    function checkFB() {
        if (!window.FB) {
            console.log('Please wait until fb will loaded!');
            return false;
        }
        return true;
    }

    function init() {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '1180853545945838',
                xfbml: true,
                version: 'v16.0',
            });
        };

        (function (d, s, id) {
            let js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = '//connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');

        return false;
    }

    async function checkLoginStatus() {
        const loaded = checkFB();
        if (!loaded) return;

        return new Promise((res, rej) => {
            return window.FB?.getLoginStatus((response) => {
                loginStatus = response.authResponse;

                if (response.status !== 'connected') {
                    return res(login());
                } else {
                    // return res(getUserLongLivedAccessToken());
                    console.log('Connected & response is ', response);
                }
            });
        });
    }

    async function login() {
        const loaded = checkFB();
        if (!loaded) return;

        return new Promise((res, rej) => {
            return window.FB.login(
                (response) => {
                    userData = response.authResponse;

                    if (response.status === 'connected') {
                        // return res(getUserLongLivedAccessToken());
                        console.log('Successfully connected');
                    } else {
                        return res(response);
                    }
                },
                {
                    scope: 'public_profile,ads_management',
                    return_scopes: true,
                },
            );
        });
    }

    async function logOut() {
        const loaded = checkFB();
        if (!loaded) return;

        return new Promise((res, rej) => {
            return window.FB.logout((response) => {
                return res(console.log('Successfully logout from facebook!'));
            });
        });
    }

    return { init, checkLoginStatus };
};

export default useFBLogin;
